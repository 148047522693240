const experienceList=[
    {
        id:4,
        name: "Celestial",
        organization: "",
        tenure: "",
        desc: "Map Celestial Bodies as you gaze upon the night sky",
        image: "milkyway.jpeg"
    },{
        id:3,
        name: "Creativity is intelligence having fun - Albert Einstein",
        organization: "",
        tenure: "",
        desc: "Meet Albert Einstein, Ada Lovelace, the Loch Ness monster and other NPCs who will aid you in your journey",
        image: "NPC_Einstein.png"
    },{
        id:2,
        name: "Newtons Laws",
        organization: "",
        tenure: "",
        desc: "Learn the Laws of Motion with Sir Isaac Newton at your neighborhood playground",
        image: "Newton_Playground.png"
    },{
        id:1,
        name: "Altamira and Lascaux",
        organization: "",
        tenure: "",
        desc: "Explore the caves of Altamira and Lascaux from your bedroom fort",
        image: "altamira_caves.png"
    }


]

export default experienceList;