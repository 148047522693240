let projectsList = [
    {
        id: 1,
        name: "Kids",
        description: "Play Educational Games in the virtual world of Wonder",
        tech: "",
        github: "",
    },
    {
        id: 2,
        name: "Parents",
        description: "Setup exploration basee Ed-ventures for kids",
        tech: "",
        github: "",
    }, {
        id: 3,
        name: "Merchants/Brands/Institutions",
        description: "Setup engagement based Ed-ventures for kids and parents",
        tech: "",
        github: "",
    }, 
]

export default projectsList;