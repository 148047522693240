//certificates here for volunteering

let volunteeringList = [
    {
        id: 8,
        name: "",
        position: "",
        date: "",
        description: "Put on your sneakers, grab your phone and level up by engaging in real-world adventures",
        image: "phone_world.png",
    },
    {
        id: 7,
        name: "",
        position: "",
        date: "",
        description: "Take a break, design your very own pod and have some friends over",
        image: "Customizable_pod.png",
    },
    {
        id: 6,
        name: "",
        position: "",
        date: "",
        description: "Be at awe with the natural world as you explore the ocean depths or gaze at the stars",
        image: "Ocean_depths.png",
    },
    {
        id: 5,
        name: "",
        position: "",
        date: "",
        description: "Learn about the world and its people at the museum of the real and the imagined",
        image: "Museum.png",
    },
    {
        id: 4,
        name: "",
        position: "",        
        date: "",
        description: "Wander through enchanted libraries where stories come alive",
        image: "Enchanted_library.png"
    },
    {
        id: 3,
        name: "",
        position: "",
        date: "",
        description: "Discover a world filled with learning and adventure",
        image: "Explore_world_of_wonder.png"
    },

]

export default volunteeringList;